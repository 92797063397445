<template>
	<div class="flex column map justify-center gap-15">
		<div class="flex title self-center">Интим-карта</div>
		<div class="flex text self-center">
			Удобный подбор предложений рядом с вами
		</div>
		<div class="flex text self-center">
			<q-btn
				outline
				class="button"
				icon="ion-globe"
				flat
				label="Смотреть на карте"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.map {
	background-image: url('~assets/images/map.webp');
	padding: 40px 0;

	color: var(--q-text-black);
	.title {
		font-size: 40px;
		font-weight: bold;
	}

	.text {
		font-size: 14px;
	}

	.button {
		// background-color: $negative;
		color: $negative;
		border: 1px solid $negative;
		font-size: 15px;
		padding: 8px 8px;
	}
}
</style>
