import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex column card" }
const _hoisted_2 = { class: "flex title" }
const _hoisted_3 = { class: "flex address" }

import { PublicSalonDto } from 'src/common/axios-client'
import { IMedia } from '@interfaces/media.interface'
import { useMediaStore } from 'src/stores/media.store'
import { onMounted, ref } from 'vue'

interface Props {
	salon: PublicSalonDto
}


export default /*@__PURE__*/_defineComponent({
  __name: 'salon-card',
  props: {
    salon: {}
  },
  setup(__props: any) {



const mediaStore = useMediaStore()

const media = __props.salon.media as IMedia[]
const image = media.filter((m) => m.kind === 'image').shift()

const imageUrl = ref<string>('')

async function setImageUrl() {
	if (!image) return
	imageUrl.value = await mediaStore.getImageUrl(image.id, undefined, 190)
}

onMounted(() => {
	setImageUrl()
})

return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_link, {
      to: `/salon/${_ctx.salon.id}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_img, {
            class: "image",
            src: imageUrl.value,
            position: "0 0",
            loading: "lazy",
            fit: "contain"
          }, null, 8, ["src"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.salon.name), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.salon.address), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})