import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex column gap-30" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "flex row" }
const _hoisted_4 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_5 = { class: "flex row" }
const _hoisted_6 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_7 = { class: "flex row" }
const _hoisted_8 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_9 = { class: "flex row full-width" }
const _hoisted_10 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_11 = { class: "flex row" }
const _hoisted_12 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}

import personCard from 'src/components/public/person-card.vue'
import salonCard from 'src/components/public/salon-card.vue'
import intimeMap from 'src/components/public/intime-map.vue'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { storeToRefs } from 'pinia'


export default /*@__PURE__*/_defineComponent({
  ...{
	async preFetch() {
		const prefetchStore = usePreFetchStore()
		return Promise.all([prefetchStore.updateSalons()])
	},
},
  __name: 'main-page',
  setup(__props) {

const prefetchStore = usePreFetchStore()

const { _salons: salons } = storeToRefs(prefetchStore)



return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _cache[1] || (_cache[1] = _createTextVNode(" Анкеты на Ashoo ")),
      _createElementVNode("sup", null, [
        _createVNode(_component_q_icon, {
          name: "trip_origin",
          size: "xs"
        }),
        _cache[0] || (_cache[0] = _createTextVNode(" 882 онлайн"))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          class: "show-all-btn",
          label: "Все"
        })
      ])
    ]),
    _createVNode(intimeMap),
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "header" }, "Новые анкеты на Ашу", -1)),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          class: "show-all-btn",
          label: "Все"
        })
      ])
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "header" }, "Топ-20", -1)),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          class: "show-all-btn",
          label: "Все"
        })
      ])
    ]),
    (_unref(salons).length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "header" }, "Салоны", -1)),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(salons), (salon) => {
              return (_openBlock(), _createBlock(salonCard, {
                class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs",
                key: salon.id,
                salon: salon
              }, null, 8, ["salon"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_btn, {
                class: "show-all-btn",
                label: "Все"
              })
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "header" }, "Массажистки на Ashoo", -1)),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" }),
      _createVNode(personCard, { class: "col-lg-3 col-md-6 col-sm-6 col-xs-12 q-py-sm q-pa-xs" })
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          class: "show-all-btn",
          label: "Все"
        })
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("p", null, " На сайте с проститутками можно найти любые анкеты девушек по параметрам (рост, вес, размер груди), цвету волос или предпочтениям в сексе. У нас представлен большой выбор проституток Москвы, готовых на все ради вашего удовольствия. У каждой девочки есть подробные анкеты с реальными фото, отзывами и координатами. Есть отдельные категории сайта по национальности, цвету волос, весу, возрасту. У нас можно найти элитную девочку, шлюху, согласную на анал, предоставляющую услугу минета. Есть и частные индивидуалки, готовые выехать в любой район твоего города. ", -1)),
    _cache[7] || (_cache[7] = _createElementVNode("p", null, " Есть отдельные категории сайта по национальности, цвету волос, весу, возрасту. У нас можно найти элитную девочку, шлюху, согласную на анал, предоставляющую услугу минета. Есть и частные индивидуалки, готовые выехать в любой район твоего города. ", -1)),
    _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Как вызвать проститутку в Москве", -1)),
    _cache[9] || (_cache[9] = _createElementVNode("p", null, " Для оказания интим услуг можно воспользоваться Telegram, WhatsApp, Viber или звонком на мобильный телефон. Номера проституток указаны в анкетах, там же есть ссылки на личные мессенджеры. ", -1)),
    _cache[10] || (_cache[10] = _createElementVNode("p", null, " На сайте гарантируется полная анонимность. Мы не сохраняем личные данные клиентов и не передаём их третьим лицам. ", -1))
  ]))
}
}

})